// Here you can add other styles
.apple-auth-btn {
    width: 300px;
}

.react-apple-signin-auth-btn {
    width: 190px;
    height: 43px;
    margin-top: 15px;
}

.budgetInputAlign {
    margin-top: 20px;
    margin-bottom: 20px;
   
}

.radioDiv {
    display: inline-block;
}

.radioDiv:not(:first-child) {
    margin-left: 20px;
}

.radioDiv label {
    margin-bottom: 0px;
    margin-left: 10px;
}

.txt-label {
    margin-right: 10px;
}

.var-month {
    text-align: center;
}
.var-month span {
    display: block;
}

.var-month:not(:first-child) {
    margin-left: 20px;
}

#new-chart canvas {
    height: 310px !important;
}

.drag-drop-container {
    display: flex; 
    justify-content: center; 
    height: 100%;
    margin-left: 12px;
}

.drag-drop-context {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.align-add-btn {
    display: flex;
}

.align-add-btn button {
    align-self: center;
}

.column-head {
    padding-left: 12px;
    width: 100%;
}

.droppable-column {
    // padding: 4,
    width: 250px;
    min-height: 500;
}

.droppable-container {
    margin: 0px 8px 0px 8px;
    width: 270px;
}

.add-task-btn {
    margin-top: 16px;
    margin-bottom: 14px;
    margin-left: auto;
    margin-right: auto;
    width: 262px;
}

.add-bucket-btn {
    cursor: pointer;
    display: inline;
    float: right;
}

.bucket-title {
    display: inline-block;
}

.bucket-delete-btn {
    float: right;
}

.x-scroll {
    overflow-x: scroll;
}

.add-btn-box  {
    width: 300px;
    padding-left: 24px;
}

.add-btn-box p {
    cursor: pointer;
}

.update-dialog-header h1 {
    display: inline-block;
}


.update-dialog-header i {
    float: right;
    margin-right: 10px;
    padding: 5px;
    cursor: pointer;
}

.task-markers {
    margin-left: 5px;
}

.attach-btns {
    margin-left: 7px;
}

.attachment-items {
    margin-top: 5px;
}

.attachment-file-name {
    width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.bucket-action-btn {
    width: 100%;
}

.category_popup_menu {
    height: 250px;
    overflow-y: scroll;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: none;
    background: none;
    font-size: 12px;
    font-weight: 400;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    // border-bottom: 3px solid #28a745;
    background: none;
    color: black;
}

.cal-view-selector {
    float: right;
}

.cal-view-selector button {
    margin-left: 5px;
}

// #monthpicker input {
//     background: none;
//     outline: none;
//     border: none;
// }

.rbc-day-bg {
    position: relative;
}

.rbc-day-bg:hover::after {    
    // background-color: yellow;
    content: "Add Task";
    position: absolute;
    right: 5px;
    bottom: 0px;
    background: #c1c1c1;
    border-radius: 6px;
    color: white;
    padding: 2px 5px;
    font-size: 11px;
    cursor: pointer;
    margin-bottom: 5px;
}

.rbc-date-cell {
    position: relative;
}
.rbc-event-content {
    font-size: 12px;
}
// .rbc-date-cell:hover::after {        
//     content: " - Add Task";
//     position: absolute;
//     right: 5px;
//     bottom: 0px;
// }

.p-datepicker table td > span {
    width: 34px;
    height: 34px;
}

#compliance .p-calendar {
    z-index: 1;
}

// .active-chip {
//     background: rgb(105, 105, 192);
// }


.drag-drop-context .MuiIconButton-root {
    padding: 0px;
    font-size: 16px;
}

.card-task-title {
    // margin-left: 5px;
}

.card-view {
    overflow-y: scroll;
    height: 100vh;   
}

.bucket-cards {
    overflow-y: scroll;
    height: 60vh;
    width: 250,
}


.droppable-inner-content {
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
}

// .droppable-inner-content{
//     overflow-y: auto;
// }

.chart-cards {
    height: 400px;
}

.quarter-due-date {
    float: right;
}

.selected-assignee {
    background: #f7f7f7;
    border: 1px dashed #f10 !important;
}

.members-list {
    padding: 5px;
}

.members-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.members-list-item {
    list-style: none;
    margin: 0;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
}

.members-list-item:hover {
    background: #f5f5f5;
}

.members-list-item:active {
    background: #fff;
    color: #4b9cd8;
}
.members-list-item span {
    margin-left: 5px;
    margin-top: 7px;
}

.user-drop-down {
    margin-left: 10px;
    vertical-align: top;
    cursor:pointer;
}


.compliance-view-table .add-months-due-date span {
    display: inline-block;
    width: 75px;
}

.due-date-container {
    width: 100%;
}

.due-date-container .p-inline-element {
    display: inline-block;
}

.editable-text {
    border: 1px solid #c7bdbd;
    border-radius: 3px;
    padding: 3px;
}

.whatsapp-background {
    background: rgba(40,167,69, 0.2);
}

.email-background {
    background: rgba(75,156,216, 0.2);    
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container,
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.customDatePickerWidth {
    // display: none;
    visibility: hidden;
}


#calendar_month_panel .p-inputwrapper {
    width: 230px;
}


.add-compliance-date {
    display: inline-block;
    width: 85px;
    font-size: 12px;
}

.doughnut-chart {
    position: relative;
}

.doughnut-tasks-left {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.doughnut-tasks-left .tasks-left-num,
.doughnut-tasks-left .tasks-left-str {
    display: block;
}

.doughnut-tasks-left .tasks-left-num {
    font-size: 24px;
}

.doughnut-tasks-left .tasks-left-str {
    font-size: 16px;
}


.custom-file-upload {
    display: inline-block;
    padding: 4px 8px;
    font-size: 10px;
    cursor: pointer;
    background: #4b9cd8;
    // border: 1px solid #4b9cd8;
    border-radius: 3px;
    color: white;
}

.file-upload-name {
    display: inline-block;
    width: 64px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
}


.file-name-update {
    display: inline-block;
    width: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#file-upload {
    display: none;
}

.table-td-text {
    display: block;
    width: 180px;
}


.bill-attach-btn {
    padding: 1px 2px !important;
    border-radius: 2px !important;
    font-size: 10px;
}

.page-wa {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .marvel-device .screen {
    text-align: left;
  }
  
  .screen-container {
    height: 100%;
  }
  
  /* Status Bar */
  
  .status-bar {
    height: 25px;
    background: #004e45;
    color: #fff;
    font-size: 14px;
    padding: 0 8px;
  }
  
  .status-bar:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .status-bar div {
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 8px;
    font-weight: 600;
  }
  
  /* Chat */
  
  .chat {
    height: calc(100% - 69px);
  }
  
  .chat-container {
    height: 100%;
  }
  
  /* User Bar */
  
  .user-bar {
    height: 55px;
    background: #005e54;
    color: #fff;
    padding: 0 8px;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }
  
  .user-bar:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .user-bar div {
    float: left;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
  }
  
  .user-bar .actions {
    float: right;
    margin: 5px 0 0 10px;
  }
  
  .user-bar .actions img {
    height:28px;
  }
  .user-bar .actions.more {
    margin: 0 12px 0 20px;
  }
  
  .user-bar .actions.attachment {
    margin: 0 0 0 20px;
  }
  
  .user-bar .actions.attachment i {
    display: block;
  /*   transform: rotate(-45deg); */
  }
  
  .user-bar .avatar {
    margin: 0 0 0 5px;
    width: 36px;
    height: 36px;
  }
  
  .user-bar .avatar img {
    border-radius: 50%;
    padding: 7px;
    background: #fff;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    display: block;
    width: 100%;
  }
  .marvel-device.nexus5 .top-bar {
    width: calc(100% - 8px);
    height: calc(100% - 6px);
    position: absolute;
    top: 3px;
    left: 4px;
    border-radius: 20px;
    background: #111111;
}
.marvel-device.nexus5 .camera {
    background: #3c3d3d;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 18px;
    left: 50%;
    z-index: 3;
    margin-left: -5px;
    border-radius: 100%;
}
.marvel-device.nexus5 {
    padding: 50px 15px 50px 15px;
    width: 280px;
    height: 410px;
    border-radius: 20px
}
.fw-wa
{
    position: absolute;
    right: 5%;
    top: 5%;
    background: #c1c1c1;
    width: 30px;
    padding: 4px;
    text-align: center;
    height: 30px;
    color: #ffffff;
    border-radius: 50%;
}
.marvel-device {
    display: inline-block;
    position: relative;
    -webkit-box-sizing: content-box !important;
    box-sizing: content-box !important;
}
.marvel-device.nexus5 .camera:before {
    background: #3c3d3d;
    width: 6px;
    height: 6px;
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: -100px;
    z-index: 3;
    border-radius: 100%;
}
.marvel-device .screen {
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 3;
    background: white;
    overflow: hidden;
    display: block;
    border-radius: 1px;
    -webkit-box-shadow: 0 0 0 3px #111;
    box-shadow: 0 0 0 3px #111;
}
  .user-bar .name {
    font-size: 17px;
    font-weight: 600;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 110px;
  }
  
  .user-bar .status {
    display: block;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
  }
  
  /* Conversation */
  
  .conversation {
    height: 25vw;
    position: relative;
    background: url("https://i.ibb.co/3s1f9Jq/default-wallpaper.png") repeat;
    z-index: 0;
  }
  
  .conversation ::-webkit-scrollbar {
    transition: all .5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }
  
  .conversation ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .conversation ::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }
  
  .conversation .conversation-container {
    //height: calc(100% - 68px);
    height: 265px;
    box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 19px;
  }
  
  .conversation .conversation-container:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Messages */
  
  .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    // z-index: -1;
  }
  
  .message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
  }
  
  .metadata .time {
    color: rgba(0, 0, 0, .45);
    font-size: 11px;
    display: inline-block;
  }
  
  .metadata .tick {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    top: 4px;
    height: 16px;
    width: 16px;
  }
  
  .metadata .tick svg {
    position: absolute;
    transition: .5s ease-in-out;
  }
  
  .metadata .tick svg:first-child {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(180deg);
            transform: perspective(800px) rotateY(180deg);
  }
  
  .metadata .tick svg:last-child {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
  }
  
  .metadata .tick-animation svg:first-child {
    -webkit-transform: perspective(800px) rotateY(0);
            transform: perspective(800px) rotateY(0);
  }
  
  .metadata .tick-animation svg:last-child {
    -webkit-transform: perspective(800px) rotateY(-179.9deg);
            transform: perspective(800px) rotateY(-179.9deg);
  }
  
  .message:first-child {
    margin: 16px 0 8px;
  }
  
  .message.received {
    background: #fff;
    border-radius: 0px 5px 5px 5px;
    float: left;
  }
  
  .message.received .metadata {
    padding: 0 0 0 16px;
  }
  
  .message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -10px;
  }
  
  .message.sent {
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    float: right;
  }
  
  .message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -10px;
  }
  
  /* Compose */
  
  .conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 40px;
    width: 100%;
    z-index: 2;
  }
  
  .conversation-compose div,
  .conversation-compose input {
    background: #fff;
    height: 100%;
  }
  
  .conversation-compose .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50% 0 0 50%;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 38px;
    height: 38px;
  }
  
  .conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 14px;
    margin: 0;
    outline: none;
    min-width: 50px;
    height: 38px;
  }
  
  .conversation-compose .photo {
    flex: 0 0 auto;
    border-radius: 0 30px 30px 0;
    text-align: center;
    width: auto;
    display: flex;
    padding-right: 6px;
    height: 38px;
  }
  
  .conversation-compose .photo img {
    display: block;
    color: #7d8488;
    font-size: 24px;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    margin-left: 10px;
  }
  
  
  .conversation-compose .send {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin-right: 8px;
    padding: 0;
    position: relative;
    outline: none;
    margin-left: .5rem;
  }
  
  .conversation-compose .send .circle {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .conversation-compose .send .circle i {
    font-size: 24px;
    margin-left: 1px;
  }
  
  /* Small Screens */
  
  @media (max-width: 768px) {
    .marvel-device.nexus5 {
      border-radius: 0;
      flex: none;
      padding: 0;
      max-width: none;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  
    .marvel-device > .screen .chat {
      visibility: visible;
    }
  
    .marvel-device {
      visibility: hidden;
    }
  
    .marvel-device .status-bar {
      display: none;
    }
  
    .screen-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    .conversation {
      height: calc(100vh - 55px);
    }
    .conversation .conversation-container {
      height: calc(100vh - 120px);
    }
  }
  .preview-dlg .p-dialog-header
  {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .preview-dlg .p-dialog-footer
  {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .p-dialog.preview-dlg
  {
    height: 100%;
    max-height: 100%;
    margin: 0;
  }
  .light-five-nine-border
{
  border: 1px solid #f9f9f9;
}
.att-dash-b
{
  border: 1px dashed #d9d9d9;
  padding: 10px;
  border-radius: 3px;
}

.client-state-label {
  display: block;
}
.on-cal input
{
  font-size: 0.8125rem !important;
    border: 1px solid #ddd;
    border-radius: 2px;
    color: #666 !important;
    box-shadow: none;
    width: 100% !important;
    box-shadow: none !important;
    outline: none;
}
.bill-rec-radio .MuiFormControlLabel-label
{
  font-size: 14px;
}

.whataspp-attach-img {
  display: block;
}

.email-attach-img {
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.display-block {
  display: block;
}


.active-list-item {
  background: #fff;
  color: #4b9cd8;
}


.client-dropdown-main {
  border: 0;
  padding: 0;
  height: auto;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 15px;
  margin-top: 5px;
  color: #1e1e2d !important;
  background: transparent;
}


.show-canvas {
  display: block;
}

.hide-canvas {
  display: none;
}

.card-actions {
  padding: 7px;
}



.mandatory-field-val {
  color: red;
  display: block;
  font-size: 10px;
  height: 12px;
}

.show-mandatory-field {
  visibility: visible;
}

.hide-mandatory-field {
  visibility: hidden;
}

.input-caret {
  position: relative;
}

.caret-icon {
  position: absolute;
  top: 10px;
  right: 6px;
  font-size: 13px;
  color: #666;
}

.whatsapp-file-download {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.chip-arr-item  {
    background: #4b9cd8;
    display: inline-block;
    padding: 3px 7px;
    font-size: 13px;
    border-radius: 1000px;
    vertical-align: middle;
    color: #ffffff;
    margin-right: 5px;
    margin-bottom: 5px;
}

.chip-arr-text {
  margin-right: 5px;
  font-size: 10px;
  font-weight: 500;
}
.chip-arr-ico {
  padding: 2px;
  font-size: 10px;
  cursor: pointer;
}

.task-image-block {
  display: inline-block;
}

.task-image-block img {
  display: block;
  cursor: pointer;
}